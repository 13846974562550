<template>
  <div class="box">
    <card :title="title">
      <div v-if="list.length == 0">暂无数据</div>
      <div
        @click="todetail(item.id, item.standardId)"
        class="boxcent"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="tiitle">
          <span>{{ item.title }}{{ item.name }}</span>
          <span class="time" v-if="item.name && title == '打榜报名'"
            >截止日期 ：{{ item.finishTime }}</span
          >
        </div>
        <div class="summary">{{ item.summary }}</div>
      </div>
    </card>
  </div>
</template>

<script>
import card from "./card"; //封装的卡片样式
export default {
  name: "describe", //首页新闻展示
  props: {
    // 内容的数组
    list: {
      typeof: [Array],
    },
    // 卡片标题
    title: {
      typeof: [String],
    },
    // 右边的更多按钮
    more: {
      typeof: [String],
      default: "更多",
    },
  },
  components: {
    card,
  },
  mounted() {},
  methods: {
    // 点击详情
    todetail(id, standardId) {
      this.$emit("todetail", id, standardId);
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .box{
      background: #ffffff;
    }
    /deep/ .box:hover{
      box-shadow: none;
    }
    .boxcent {
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.0625rem solid #a5a5a5;
    }
    .boxcent:last-child {
      border-bottom: none;
    }
    .summary {
      font-size: 1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .tiitle {
      font-size: 1.2rem;
      /*font-weight: 600;*/
      letter-spacing: 0.125rem;
      margin-bottom: 0.625rem;
      display: flex;
      .time {
        flex: 1;
        text-align: right;
      }
    }
    .tiitle:hover {
      span {
        color: #1639af;
        text-decoration: underline;
      }
      .time {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .boxcent {
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.0625rem solid #a5a5a5;
    }
    .boxcent:last-child {
      border-bottom: none;
    }
    .summary {
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .tiitle {
      font-size: 1rem;
      /*font-weight: 600;*/
      letter-spacing: 0.1rem;
      margin-bottom: 0.125rem;
    }
  }
}
</style>

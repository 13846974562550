<template>
  <!-- 工作组内容-->
  <div class="box">
    
    <div v-if="chinum == 0">
      <!-- <div>duuuuu</div> -->
      <hierarchy :title="narlist[0].name" />
    </div>
    <div v-if="chinum == 1">
      <chart :title="narlist[1].name" :list="list" />
    </div>
  </div>
</template>

<script>
import hierarchy from "./hierarchy"; //标准体系
import chart from "./chart"; //标准明细

export default {
  name: "workgroup", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    // 当前的页码(1级菜单)
    narlist: {
      typeof: [Array],
    },
  },
  components: {
    hierarchy, //标准体系
    chart, //标准明细
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      list: [], //当前数据数组
    };
  },
  // 进入页面同步数据
  mounted() {
    this.secondary = this.chinum; //同步一级菜单
    if (this.chinum == 1) {
      this.getlist();
    }
  },
  methods: {
    // 点击切换菜单样式
    changenar(index, ids) {
      //一级菜单的选中样式
      (this.primary = index),
        //二级菜单的选中样式
        (this.secondary = ids);
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: index, chinum: ids });
    },
    // 获取标准明细接口
    getlist() {
      // 发起请求
      this.$api
        .getCriterionLiCst({
          current: 1, //当前页
          size: 20, //页码大小
          status: 1, //状态
          name: "", //标准名称
          cateroyCode: -1,
          standardType: "-1",
        })
        .then((res) => {
          this.list = res.data.records;
        });
    },
  },
};
</script>

<style scoped lang="less">

@media screen and (min-width: 1024px) {
  /deep/ .cardcent {
    min-height: 30vh;
  }
  /deep/ .cardhead {
  height: 3.75rem !important;
  background-color: #e1e1e6;
  .more {
    display: none;
  }
}
}

@media screen and (max-width: 1023px) {
  .box{
    width: 22.5rem;
    /deep/ .tabcahrt {
      width: 100%;
    }
   /deep/ .more {
    display: none;
    }
  }
}
</style>

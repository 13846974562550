<template>
  <div>
    <!-- 头部 -->
    <div v-if="istrue" class="topheader">
      <!-- 引入的头部组件（传入页面数据 -->
      <headerbox :list="list" @changenar="changenar" :pagenum="primary"
        :chinum="secondary"/>
    </div>
    <!-- 内容 -->
    <div class="mainpage">
      <slot />
    </div>
    <!-- 尾部 -->
    <div>
      <footerbox
        :list="list"
        @changenar="footernar"
        :pagenum="primary"
        :chinum="secondary"
      />
    </div>
  </div>
</template>

<script>
//  引入的头部组件
import headerbox from "../header/headerbox";
//  引入的尾部组件
import footerbox from "../footer/footerbox";
export default {
  name: "pagebox", //页面盒子
  props:{
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  data() {
    return {
      list: [], //导航栏数组
      primary: 0, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
      istrue: true, //刷新导航栏数据
    };
  },
  created(){
    this.primary=this.pagenum
    this.secondary=this.chinum
  },
  mounted() {
    // 进入页面加载导航栏数据
    this.getlist();
  },
  components: {
    headerbox, //全局引用页面样式
    footerbox, //引入的尾部组件
  },
  methods: {
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.list = res.data;
      });
    },
    // 点击导航传出当前选中菜单
    changenar(e) {
      this.$emit("changenar", e);
    },
    footernar(e) {
      //一级菜单的选中样式
      this.primary = e.pagenum;
      //二级菜单的选中样式
      this.secondary = e.secondary;
      // 传出当前选中菜单
      this.$emit("changenar", e);
      this.istrue = false;
      this.$nextTick(() => {
        this.istrue = true;
      });
    },
  },
};
</script>

<style scoped lang="less">

.topheader{
  position: sticky;
  z-index: 100;
  top: -5rem;
}
@media screen and (min-width: 1024px) {
  .mainpage {
  // min-height: 60vh;
}
  
}
@media screen and (max-width: 1023px) {
  .mainpage {
  min-height: 67.5vh;
}
}
</style>

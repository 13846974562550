<template>
  <div class="box">
    <card :title="title" v-if="record.length > 0">
      <!-- 标题内容 -->
      <div @click="down" class="flexcent">下载</div>
      <div style="height: 1rem;"></div>
      <div class="ql-editor" v-html="record[0].content"></div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "hierarchy", //首页新闻展示
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
  },
  data() {
    return {
      imglist: [
        this.$base.baseUrl +
          "/admin/sys-file/cppb/3636846efb0a4249b94505a29036536b.png",
      ],
      record: [],
      isdown: false,
    };
  },
  components: {
    card,
  },
  mounted() {
    this.getdata();
  },
  watch: {},
  methods: {
    // 用户点击进行下载
    down() {
      // var info = this.until.cacheSync("get", "pigx-userInfo");
      // if (info) {
         window.open(
        this.$base.baseUrl +
          `/admin/sys-file/${this.record[0].fileurl}/${this.record[0].filename}`,
        "_parent"
      )
      // }else{
      //   this.$message.warning("6请登录后进行下载")
      // }
     
    },
    //   获取标准体系
    getdata() {
      // 发起请求
      this.$api
        .systemData({
          current: 1, //当前页
          size: 20, //页码大小
        })
        .then((res) => {
          this.record = res.data.records;
        });
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    position: relative;
    /deep/ .box{
      background: #ffffff;
    }
    /deep/ .box:hover{
      box-shadow: none;
    }
    .flexcent {
      position: absolute;
      right: 1.5rem;
      width: 6.25rem;
      height: 2rem;
      border-radius: 0.25rem;
      background-color: #409eff;
      color: #ffffff;
    }
    .flexcent:hover {
      background-color: #7996f5;
    }
    .flexcent:active {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    position: relative;
    .flexcent {
      position: absolute;
      right: 1.5rem;
      width: 5rem;
      height: 1.8rem;
      border-radius: 0.25rem;
      background-color: #2317c9;
      color: #ffffff;
    }
    .flexcent:active {
      opacity: 0.7;
    }
  }
}
</style>

<template>
  <div class="box">
    <card :title="title">
      <div v-if="list.length == 0">暂无数据</div>
      <div @click="todetail(item.id)" class="boxcent flexspb" v-for="(item, index) in list" :key="index">
        <div class="text">{{ item.title }}</div>
        <!-- 时间 -->
        <div class="time">{{ item.pushTime }}</div>
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "newsbox", //首页新闻展示
  props: {
    // 内容的数组
    list: {
      typeof: [Array],
    },
    // 卡片标题
    title: {
      typeof: [String],
    },
  },
  components: {
    card,
  },
  methods:{
    // 查看详情
    todetail(id){
      this.$emit('todetail',id)
    }
  }
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .box{
      background: #ffffff;
    }
    /deep/ .box:hover{
      box-shadow: none;
    }
    .boxcent {
      .text {
        width: 43.75rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time{
          width: 6.25rem;
      }
    }
    .boxcent:hover {
      color: #1540ce;
    }
  }
}

@media screen and (max-width: 1023px) {
   // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .boxcent {
      .text {
        font-size: .8rem;
        width: 13.75rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time{
         font-size: .8rem;
          width: 5.25rem;
      }
    }
    .boxcent:hover {
      color: #1540ce;
    }
  }
}
</style>

<template>
  <div class="box">
    <card :title="title" v-if="list.length > 0">
      <el-table :data="list" @row-click="todetail" class="tabcahrt" border>
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column property="name" label="标准名称"> </el-table-column>
        <el-table-column property="cateroyCode" label="分类"> </el-table-column>
        <el-table-column property="standardType" label="子类">
        </el-table-column>
        <el-table-column property="code" label="标准号"> </el-table-column>
      </el-table>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "chart", //表格
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
    list: {
      typeof: [Array],
    },
  },
  data() {
    return {
      record: {},
    };
  },
  components: {
    card,
  },
  mounted() {},
  methods: {
    //  点击当前行
    todetail(e) {
      var obj = {
        id: e.id,
        chinum: 1,
        pagenum: 3,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    margin-bottom: 1.25rem;
    /deep/ .box {
      background: #ffffff;
      .cardcent {
        padding: 0;
      }
    }
    /deep/ .box:hover {
      box-shadow: none;
    }
    .tabcahrt {
      border: 0.0625rem solid #ececec;
    }
    /deep/ .el-table {
      font-size: .9rem !important;
      .cell {
        display: flex;
      align-items: center;
      justify-content: center;
      height: 3.125rem;
      line-height: 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    margin-bottom: 1.25rem;
    .tabcahrt {
      border: 0.0625rem solid #ececec;
      width: 80%;
      box-sizing: border-box;
    }
    /deep/ .el-table {
      font-size: .5rem !important;
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 2.125rem;
        line-height: 1.25rem;
      }
    }
  }
}
</style>

<template>
  <!-- 工作组内容-->
  <div class="box">
    <div
      class="bombox"
      ref="firstcsol"
      v-if="(innerht < 1024 && chinum == 0) || innerht > 1023"
    >
      <describe
        @todetail="firdetail"
        :title="narlist[0].name"
        :list="calculatelist"
      />
    </div>
    <div
      class="bombox"
      ref="secondcsol"
      v-if="(innerht < 1024 && chinum == 1) || innerht > 1023"
    >
      <describe
        @todetail="secdetail"
        :title="narlist[1].name"
        :list="serverlist"
      />
    </div>
    <!--打榜暂时不起用 -->
    <!--<div
      class="bombox"
      v-if="(innerht < 1024 && chinum == 2) || innerht > 1023"
    >
      <describe
        @todetail="thrdetail"
        :title="narlist[2].name"
        :list="enrolllist"
      />
    </div>-->
  </div>
</template>

<script>
import describe from "../../all/describe";
const defaultPrimary = 2
export default {
  name: "billboard", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    // 当前的页码(1级菜单)
    narlist: {
      typeof: [Array],
    },
  },
  components: {
    describe, //内容盒子
  },
  watch: {
    enrolllist: function () {
      this.$nextTick(() => {
        if (this.innerht > 1023) {
          this.scolist[1] = this.$refs.firstcsol.offsetHeight + 100;
          this.scolist[2] =
            this.$refs.firstcsol.offsetHeight +
            this.$refs.secondcsol.offsetHeight +
            120;
          //
          var time = setTimeout(() => {
            this.$emit("scollist", this.scolist);
            clearTimeout(time);
          },100);
        }
      });
    },
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      calculatelist: [], //通用计算基准数组
      serverlist: [], //通用通用服务器基准数组
      enrolllist: [], //打榜报名
      scolist: [0, 0, 0],
      innerht: window.innerWidth,
    };
  },
  // 进入页面同步数据
  created() {
    this.secondary = this.chinum; //同步一级菜单
  },
  mounted(){
    this.getbilllist();
  },
  methods: {
    // 获取详情
    firdetail(id) {
      var obj = {
        id: id,
        chinum: 0,
        pagenum: defaultPrimary
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    // 获取详情
    secdetail(id) {
      var obj = {
        id: id,
        chinum: 1,
        pagenum: defaultPrimary
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    // 获取详情
    thrdetail(id, standardId) {
      var obj = {
        id: standardId,
        chinum: 2,
        pagenum: defaultPrimary,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    // 点击切换菜单样式
    changenar(index, ids) {
      //一级菜单的选中样式
      (this.primary = index),
        //二级菜单的选中样式
        (this.secondary = ids);
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: index, chinum: ids });
    },
    // 大榜单接口分装 在原有榜单上又加了一层
    async getBigBillList(num) {
      return new Promise((resolve) => {
        this.$api
          .bigBillboardList({
            current: 1, //当前页
            size: 20, //页码数量
            cateroyCode: num, //活动分类
            name: "", //大榜单名称
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    // 普通榜单接口分装
    async getlist(num) {
      return new Promise((resolve) => {
        this.$api
          .billboardList({
            current: 1, //当前页
            size: 20, //页码数量
            title: "", //标题
            status: 1, //状态
            cateroyCode: num, //活动分类
            name: "", //榜单名称
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    getbilllist() {
      // 查询大榜单
      this.getBigBillList(10).then((res) => {
        this.calculatelist = res.records;
      });
      this.getBigBillList(11).then((res) => {
        this.serverlist = res.records;
        // this.getthidlist();
      });

      // this.getlist(10).then((res) => {
      //   this.calculatelist = res.records;
      // });
      // this.getlist(11).then((res) => {
      //   this.serverlist = res.records;
      //   this.getthidlist();
      // });
    },
    getthidlist() {
      this.$api.getSiugnupList({}).then((res) => {
        this.enrolllist = res.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  /deep/ .cardcent {
    min-height: 30vh;
  }
  .bombox {
    margin-bottom: 1.25rem;
  }
  /deep/ .cardhead {
    height: 3.75rem !important;
    background-color: #e1e1e6;
    .more {
      display: none;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .more {
    display: none;
  }
}
</style>

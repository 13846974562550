<template>
  <!-- 工作组内容-->
  <div class="box">
    <div class="bombox">

    </div>
    <card
      title="下载指引"
    >
      <div v-if="chinum !== 2 && chinum !== '2'">
        <div class="header">
          工具申请流程
        </div>
        <ul class="content">
          <!-- <li>请先申请成为工作组成员单位 <a href="/#/about?pagenum=6&chinum=2">加入我们</a></li> -->
          <!-- <li>使用带有公司后缀的邮箱发送工具下载申请到工作组邮箱（cppb_wg@cesi.cn）,注明下载申请单位、联系人、电话及本单位工作组账号</li> -->
          <li v-if="userinfo.username && userinfo.username != ''">CPUBench V1.2.0 <a href="https://pan.baidu.com/s/1FGpzPUd1bougBLAudYKUvw">百度云盘链接</a> 提取码：7vz2 </li>
          <li v-else>请先<a style="color: blue;" @click="login">登录</a></li>
        </ul>
      </div>
      <div v-else>
        <div class="header">
          CPUBench参考测试配置文件
        </div>
        <ul class="content">
          <li>
            <a href="/admin/sys-file/cppb/飞腾.rar">飞腾推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/海光.rar">海光推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/合芯.rar">合芯推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/鲲鹏.rar">鲲鹏推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/龙芯.rar">龙芯推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/申威.rar">申威推荐配置文件</a>
          </li>
          <li>
            <a href="/admin/sys-file/cppb/兆芯.zip">兆芯推荐配置文件</a>
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>

<script>
// import describe from "../../all/describe";
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "tools", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  components: {
    // describe, //内容盒子
    card,
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      narlist: this.until.cacheSync("get", "navigation").data[5].children, //当前导航栏数组
      losgolist: [], //当前数据数组
      sendlist: [],
      scolist: [0, 0],
      innerht: window.innerWidth,
      userinfo: {
      },
    };
  },
  // 进入页面同步数据
  mounted() {
    this.secondary = this.chinum; //同步一级菜单
    console.log('narlist', this.chinum)
    this.getbilllist();
  },
  created() {
    var info = this.until.cacheSync("get", "pigx-userInfo");
    if (info) {
      this.userinfo = info.content;
      console.log('this.userinfo', this.userinfo)
    }
  },
  watch: {
    losgolist: function () {
      // this.$nextTick(function () {
      //   if (this.innerht > 1023) {
      //     this.scolist[1] = this.$refs.firstcsol.offsetHeight + 100;
      //     var time = setTimeout(()=>{
      //       this.$emit("scolist", this.scolist);
      //       clearTimeout(time)
      //     })
      //   }
      // });
    },
  },
  methods: {
    firdetail(id) {
      var obj = {
        id: id,
        chinum: 0,
        pagenum: 4,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    secdetail(id) {
      var obj = {
        id: id,
        chinum: 1,
        pagenum: 4,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    // 点击切换菜单样式
    changenar(index, ids) {
      //一级菜单的选中样式
      (this.primary = index),
        //二级菜单的选中样式
        (this.secondary = ids);
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: index, chinum: ids });
    },
    getbilllist() {
      this.getlist(15).then((res) => {
        this.losgolist = res.records;
      });
      this.getlist(16).then((res) => {
        this.sendlist = res.records;
      });
    },
    async getlist(num) {
      return new Promise((resolve) => {
        this.$api
          .getToolList({
            current: 1, //当前页
            size: 20, //页码数量
            title: "", //标题
            status: 1, //状态
            cateroyCode: num, //活动分类
            name: "", //榜单名称
            typeCode: 0,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    login(){
        this.until.jump.call(this, "/uiadmin", "href");
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  /deep/ .cardcent {
    min-height: 30vh;
  }
  .bombox {
    margin-bottom: 1.25rem;
  }
  /deep/ .cardhead {
    height: 3.75rem !important;
    background-color: #e1e1e6;
    .more {
      display: none;
    }
  }
  .header {
    font-size: 1.125rem;
    font-weight: 550;
    border-bottom: 0.0625rem solid #ddd;
  }
  .content {
    margin-left: 1rem;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .more {
    display: none;
  }
}
</style>

<template>
  <!-- 工作组内容-->
  <div class="box">
    <newsbox @todetail='todetail' :title="narlist[chinum].name" :list="list" />
  </div>
</template>

<script>
import newsbox from "./newsbox";
export default {
  name: "workgroup", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    narlist:{
       typeof: [Array],
    },
  },
  components: {
    newsbox, //内容盒子
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      list: [], //当前数据数组
    };
  },
  // 进入页面同步数据
  created() {
    console.log('created')
    this.secondary = this.chinum; //同步一级菜单
    this.getlist()
  },
  methods: {
    // 点击跳转到详情
    todetail(id){
      var obj ={
        id:id,
        chinum:this.secondary,
        pagenum:1,
      }
      //  进行路由跳转
      this.until.jump.call(this, "/detail", "push", obj);
    },
    // 点击切换菜单样式
    changenar(index, ids) {
      //一级菜单的选中样式
      (this.primary = index),
        //二级菜单的选中样式
        (this.secondary = ids);
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: index, chinum: ids });
    },
    // 获取当前数据
    getlist() {
      let newsType  = parseInt( this.secondary ) + 1
      this.$api
        .getWorkingList({
          current: 1, //当前页
          size: 20, //页码数量
          title: "", //标题
          status: 1, //状态
          newsType: newsType, //活动分类
        })
        .then((res) => {
          this.list = res.data.records;
        });
    },
  },
};
</script>

<style scoped lang="less">

@media screen and (min-width: 1024px) {
  /deep/ .cardcent {
    min-height: 30vh;
  }
  /deep/ .cardhead {
  height: 3.75rem !important;
  background-color: #e1e1e6;
  .more {
    display: none;
  }
}
}

@media screen and (max-width: 1023px) {
   /deep/ .more {
    display: none;
}
}
</style>

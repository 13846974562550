import { render, staticRenderFns } from "./hierarchy.vue?vue&type=template&id=0b47c0b7&scoped=true&"
import script from "./hierarchy.vue?vue&type=script&lang=js&"
export * from "./hierarchy.vue?vue&type=script&lang=js&"
import style0 from "./hierarchy.vue?vue&type=style&index=0&id=0b47c0b7&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b47c0b7",
  null
  
)

export default component.exports